import * as ADMINS from './admin'
import * as CONTENTS from './contents'
import * as COURSES from './courses'
import * as MISC from './misc'
import * as MODULES from './modules'
import * as ORDERS from './orders'
import * as PAYMENT from './payment'
import * as PROMOTION from './promotion'
import * as QUESTIONS from './questions'
import * as STUDENTS from './students'

export const API = {
  COURSES,
  MODULES,
  CONTENTS,
  QUESTIONS,
  ORDERS,
  STUDENTS,
  ADMINS,
  MISC,
  PAYMENT,
  PROMOTION,
}
